import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Route } from 'react-router-dom';
import { LoadImage } from '@bit/fixcode.react.ui.custom-image';
import { TestimonialsPopup, MobileSlider, Loading } from '.';
import config from '../config';
import { Desktop, Mobile } from '../ResponsiveRules';

const getInitials = name => {
  const [first, second] = name.split(' ');

  return `${first[0] || ''}${second[0] || ''}`;
}

const Testimonial = ({ testimonial: { image, name, city, text } }) => (
	<div className="testimonial">
		{/* <div className="testimonial-image">
			{ image && <LoadImage src={`${config.API}/${image.url}`} alt={name} /> }
			{!image && (
        <p>{getInitials(name)}</p>
      )}
		</div> */}
		<strong>{name}</strong>
		{ city && <span>{city}</span> }
		<div>
			<img src={require('../img/quotes.svg')} alt="aspas"/>
			<p dangerouslySetInnerHTML={{__html: `${text.replace(/\n/g, '<br />')}` }} />
		</div>
	</div>
)

class TestimonialsList extends Component {
	componentDidMount() {
		this.props.fetchAll();
	}

	render() {
		const { testimonials } = this.props;
		return (
			<>
				<Route path="/depoimentos" component={TestimonialsPopup} />
				{testimonials.length === 0 && <Loading />}
				{testimonials.length > 0 && (
					<>
						<div className="testimonials-list">
							<Desktop>
								{testimonials.map((testimonial, index) => <Testimonial testimonial={testimonial} key={index} />)}
							</Desktop>
							<Mobile>
								<MobileSlider>
									{testimonials.map((testimonial, index) => <Testimonial testimonial={testimonial} key={index} />)}
								</MobileSlider>
							</Mobile>
						</div>
				
						{testimonials.length > 40 && <Link className="round-button" to="/depoimentos">mais</Link>}
					</>
				)}
			</>
		)
	}
}

const mapStateToProps = ({ testimonials: { data: testimonials } }) => ({ testimonials });

const mapDispatchToProps = dispatch => ({
	fetchAll: () => dispatch.testimonials.fetchAll()
});

export default connect(mapStateToProps, mapDispatchToProps)(TestimonialsList);