import React, { Component } from 'react';
import { connect } from 'react-redux';
import CustomPopup from '@bit/fixcode.react.ui.custom-popup';
import { Loading } from '.';

class ArticlePopup extends Component {
	componentDidMount() {
		this.props.fetch(this.props.match.params.slug);
	}

	render() {
		const { article, history } = this.props;

		return (
			<CustomPopup
				className={`popup-article${!article ? ' isLoading' : ''}`}
				portalQuerySelector="#portal"
				onBack={() => history.push('/')}
			>
				{!article && <Loading />}
				{article && (
					<div className="popup-article-container">
						<h1>{article.title}</h1>
						<div className="popup-article-content" dangerouslySetInnerHTML={{ __html: article.content }}></div>
					</div>
				)}
			</CustomPopup >
		)
	}
}

const mapStateToProps = ({ article: { data: article } }) => ({ article })

const mapDispatchToProps = dispatch => ({
	fetch: slug => dispatch.article.fetch(slug)
})

export default connect(mapStateToProps, mapDispatchToProps)(ArticlePopup)