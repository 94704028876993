import { LoadImage } from '@bit/fixcode.react.ui.custom-image';
import React, { Component } from 'react';
import CustomSlider from '@bit/fixcode.react.ui.custom-slider';
import { withRouter, Link } from 'react-router-dom';

class PhotoAlbum extends Component {
  constructor(props) {
    super(props);

		const pathname = props.location.pathname.split('/')[1];
    const sliderPath = props.items.findIndex(slide => slide.path === pathname);

    this.state = { 
      currentIndex: sliderPath !== -1 ? sliderPath : 0, 
      direction: 'next', 
      length: 0, 
      isAnimating: false, 
      initialSlide: sliderPath !== -1 ? sliderPath : 0,
      changingByClick: false,
      hasAutoPlay: (sliderPath === -1)
    };
  }

  paginate(direction) {
    let { currentIndex, length } = this.state;

    this.setState({direction}, () => {
      this.setState({currentIndex}, () => {
        this.setState({ isAnimating: true })
        setTimeout(() => {
          if (direction === 'next')
            this.slider.slickNext();
          else
            this.slider.slickPrev();
          setTimeout(() => this.setState({ isAnimating: false }), 1500);
        }, 250);
      });
    });
  }

  componentDidMount() {
    this.setState({ length: this.slider.props.children.length - 1 });

  }

  renderSlider() {
    const { items, contentKey } = this.props;
    const { currentIndex, length, isAnimating, initialSlide, changingByClick, hasAutoPlay } = this.state;
    const settings = {
      dots: true,
      arrows: false,
      swipe: false,
      speed: 1000,
      autoplaySpeed: 10000,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      lazyLoad: false,
      easing: 'ease',
      initialSlide: initialSlide,
      autoplay: hasAutoPlay,
      beforeChange: (oldIndex, newIndex) => {
        this.setState({ currentIndex : newIndex });
        if(!changingByClick){
          this.paginate('next');
        }
      },
      responsive: [{
        breakpoint: 997,
        settings: {
          swipe: true,
          dots: true
        }
      }]
    };
    
    return (
      <>
        <CustomSlider ref={c => this.slider = c } sliderOptions={settings} className="slider slider">
          { items.map( (item, index) => (<LoadImage key={`${contentKey}-photo-${index}`} alt="" src={`${item.url}`} />) ) }
        </CustomSlider>
        <span className="stripe"><span /></span>
        <div className="overlay">
          <div className={isAnimating ? 'hide': ''}>
            {!isAnimating && (
              <div className={items[currentIndex].position}>
                <h2>{items[currentIndex].title}</h2>
                <p dangerouslySetInnerHTML={{__html: items[currentIndex].text }} />
                {items[currentIndex].button && (
                  <>
                    {items[currentIndex].button.target && (
                      <a href={`${items[currentIndex].button?.link}`} target={items[currentIndex].button.target}>
                        {items[currentIndex].button?.text}
                      </a>
                    )}

                    {!items[currentIndex].button.target && (
                      <Link to={`${items[currentIndex].button?.link}`}>{items[currentIndex].button?.text}</Link>
                    )}
                  </>
                )}
              </div>
            )}
            
          </div>
        </div>
        <div className="slick-buttons">
          { currentIndex !== 0 &&
            <button 
            className={`slick-arrow slick-prev ${isAnimating ? 'slick-disabled' : ''}`} 
            onClick={() => {
              this.paginate('prev');
              this.setState({ changingByClick: true }, () => {
                setTimeout(() => {
                  this.setState({ changingByClick: false })
                }, 1500);
              })
            }} /> 
          }
          { currentIndex !== length &&
            <button 
            className={`slick-arrow slick-next ${isAnimating ? 'slick-disabled' : ''}`} 
            onClick={() => {
              this.paginate('next');
              this.setState({ changingByClick: true }, () => {
                setTimeout(() => {
                  this.setState({ changingByClick: false })
                }, 1500);
              })
            }} />
          }
        </div>
      </>
    );
  }

  render() {
    const { parallax, startsAuto, id } = this.props;
    const { direction, isAnimating } = this.state;
    const props = { startsAuto, id };
    return (
      <div className="wrapper-player-banner with-parallax">
        <div  className={` parallax-wrapper container-player-banner ${direction} ${isAnimating ? 'animate' : ''}`} >
          { this.renderSlider() }
        </div>
      </div>
    )
  }
}

export default withRouter(PhotoAlbum);