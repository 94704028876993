import React from 'react';
import { Link } from 'react-router-dom';
import { LoadImage } from '@bit/fixcode.react.ui.custom-image';
import { MobileSlider, Loading } from '.';
import config from '../config';
import { Desktop, Mobile } from '../ResponsiveRules';

const Article = ({ article: { slug, image, title, description } }) => (
	<Link to={`/artigo/${slug}`} className="article" title={title}>
		<div className="article-image">
			<LoadImage src={`${config.API}/${image.url}`} alt={title} />
		</div>
		<strong>{title}</strong>
		<p>{description}</p>
		<span>Leia mais</span>
	</Link>
);

const ArticlesList = ({ articles, noSlider, handleMoreClick, hasMore, isLoading }) => {
	if (!articles)
		return null;
	return (
		<>
			<div className="articles-list">
				<Desktop>
					{articles.map((article, index) => <Article article={article} key={index} />)}
				</Desktop>
				<Mobile>
					<MobileSlider>
						{articles.map((article, index) => <Article article={article} key={index} />)}
					</MobileSlider>
				</Mobile>
			</div>
			<Desktop>
				{isLoading && <Loading />}
			</Desktop>
			{hasMore && <button className="round-button" onClick={handleMoreClick}>mais</button>}
		</>
	);
}

export default ArticlesList;