import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import FormWeCallYou from './pages/FormWeCallYou';
import Home from './pages/Home';
import SuccessMessage from './pages/SuccessMessage';

const App = () => (
  <Router>
    <Switch>
      <Route path="/ligamos-para-voce/sucesso" component={() => <SuccessMessage message="Obrigado por entrar em contato, ligaremos para você em breve" />}/>
      <Route path="/ligamos-para-voce" component={FormWeCallYou}/>
      
      <Route path="/" component={Home}/>
    </Switch>
  </Router>
);

export default App;