import * as Yup from 'yup'

export default () => {
	return Yup.object().shape({
		name: Yup.string()
			.required('Informe seu nome'),
		phone: Yup.string()
			.required('Informe seu telefone')
			.test('phone-validation', 'Insira um telefone válido', value => value ? value.replace(/\D/g, '').length >= 10 : false),
	})
};