import React, { Component } from 'react';
import { connect } from 'react-redux';
import CustomPopup from '@bit/fixcode.react.ui.custom-popup';
import { LoadImage } from '@bit/fixcode.react.ui.custom-image';
import config from '../config';
import { Loading } from '.';

const Testimonial = ({ testimonial: { image, name, text } }) => (
	<div className="testimonial">
		<div className="testimonial-image">
			<LoadImage src={`${config.API}/${image.url}`} alt={name} />
		</div>
		<strong>{name}</strong>
		<p>{text}</p>
	</div>
)

class TestimonialsPopup extends Component {
	render() {
		const { history, testimonials } = this.props;
		return (
			<CustomPopup
				className={`popup-testimonials${testimonials.length === 0 ? ' isLoading' : ''}`}
				portalQuerySelector="#portal"
				onBack={() => history.push('/')}
			>
				{testimonials.length === 0 && <Loading />}
				{testimonials.length > 0 && (
					<>
						<h1>Depoimentos</h1>
						<h2>Veja o que falam sobre nossa entrega</h2>
						<div className="testimonials-list">
							{testimonials.map((testimonial, index) => <Testimonial testimonial={testimonial} key={index} />)}
						</div>
					</>
				)}
			</CustomPopup>
		)
	}
}

const mapStateToProps = ({ testimonials: { data: testimonials } }) => ({ testimonials });

export default connect(mapStateToProps)(TestimonialsPopup);
