import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Route, withRouter } from 'react-router-dom';
import { ArticlePopup, ArticlesList, ArticlesPopupMobile, Loading } from '.';

class Articles extends Component {
	componentDidMount() {
		this.props.fetch();
	}

	handleMoreClick = () => window.innerWidth <= 997 ? this.props.history.push('/artigos') : this.props.fetch();

	render() {
		const { articles, isLoading } = this.props;
		const hasMore = articles ? articles.total > articles.skip + articles.limit : false;
		return (
			<>
				<Route path="/artigo/:slug" component={ArticlePopup} />
				<Route path="/artigos" component={ArticlesPopupMobile} />
				<section className="articles-wrapper" name="artigos">
					<h2>Artigos</h2>
					<h3>Nossa opinião sobre as leis e mercado</h3>
					{!articles && <Loading />}
					{articles && articles.data && <ArticlesList articles={articles.data} handleMoreClick={this.handleMoreClick} hasMore={hasMore} isLoading={isLoading} />}
				</section>
			</>
		)
	}
}

const mapStateToProps = ({ articles: { data: articles, isLoading } }) => ({ articles, isLoading });

const mapDispatchToProps = dispatch => ({
	fetch: () => dispatch.articles.fetch()
});

export default compose(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps)
)(Articles);
