import React, { Component } from 'react'
import { ErrorMessage, Field, Form, withFormik } from 'formik';
import { compose } from 'redux';
import { connect } from 'react-redux';
import MaskedInput from 'react-text-mask';
import { CustomField } from '.';
import { ContatoValidation } from '../validations';

const subjects = ['Quero contratar', 'Dúvidas', 'Sugestões', 'Outros'];

class FormContato extends Component {
	state = { successMessage: false }

	componentDidUpdate(prevProps) {
		const { resetForm, success } = this.props;
		if (success && prevProps.success !== success) {
			resetForm();
			this.setState({ successMessage: true }, () => {
				setTimeout(() => this.setState({ successMessage: false }), 5000);
			})
		}

	}

	render() {
		const { error, isValid, isSaving } = this.props;
		const { successMessage } = this.state;
		return (
			<Form className="form-contato">
				<Field name="name" type="text" component={CustomField} placeholder="Nome" />
				<Field name="email" type="email" component={CustomField} placeholder="E-mail" />
				<Field name="phone" render={({ field }) => (
					<div className="input phone">
						<MaskedInput
							mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
							guide={false}
							placeholder="Telefone"
							type="text"
							name="phone"
							{...field}
						/>
						<label>Telefone</label>
						<ErrorMessage component="span" name={field.name} className="error" />
					</div>
				)} />
				<Field name="subject" render={({ field }) => (
					<div className="select subject">
						<select {...field}>
							<option value="" disabled hidden>Assunto</option>
							{subjects.map(subject => <option key={`option-${subject}`} value={subject}>{subject}</option>)}
						</select>
						<ErrorMessage component="span" name={field.name} className="error" />
					</div>
				)} />
				<Field name="message" render={({ field }) => (
					<div className="message">
						<textarea placeholder="Mensagem" name="message" {...field} />
						<label className="textarea">Mensagem</label>
						<ErrorMessage component="span" name={field.name} className="error" />
					</div>
				)} />
				<button className="arrow-button" type="submit" disabled={!isValid || isSaving}>{isSaving ? 'Enviando' : 'Enviar'}<img src={require('../img/icon-right-arrow.svg')} alt="" /></button>
				{error && <p className="contato-error">Falha ao enviar a mensagem. Tente novamente mais tarde.</p>}
				{successMessage && <p className="contato-success">Mensagem enviada com sucesso.</p>}
			</Form>
		)
	}
}

const mapStateToProps = ({ messages: { isSaving, error, success } }) => ({ isSaving, error, success });

const mapDispatchToProps = dispatch => ({
	submit: data => dispatch.messages.submit(data)
});

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withFormik({
		mapPropsToValues: () => ({ name: '', email: '', phone: '', subject: '', message: '' }),
		validationSchema: ContatoValidation,
		handleSubmit: (values, { props: { submit } }) => submit(values)
	})
)(FormContato);
