import axios from '../lib/axios';

export default {
	state: { isLoading: false, data: null, all: [], error: null },
	reducers: {
		before: state => ({ ...state, isLoading: true, error: null }),
		beforeFetchAll: state => ({ ...state, isLoading: true, all: [], error: null }),
		onSuccess: (state, payload) => ({ ...state, isLoading: false, ...payload }),
		onError: (state, error) => ({ ...state, isLoading: false, error })
	},
	effects: dispatch => ({
		async fetch(_prevState, rootState) {
			dispatch.articles.before();
			try {
				let $limit = 4, data = [], $skip = 0;
				const { articles: { data: prevData } } = rootState;
				if (prevData) {
					data = prevData.data;
					$skip = prevData.skip + $limit;
				}

				const res = await axios.get('/articles', { params: { $limit, $skip } });
				dispatch.articles.onSuccess({ data: { ...res.data, data: [...data, ...res.data.data] } });
			} catch (error) {
				dispatch.articles.onError(error);
			}
		},

		async fetchAll() {
			dispatch.articles.beforeFetchAll();
			try {
				const { data } = await axios.get('/articles', { params: { $limit: 'all' } });
				dispatch.articles.onSuccess({ all: data.data });
			} catch (error) {
				dispatch.articles.onError(error);
			}
		}
	})
}