import React, { Component } from 'react';
import { Desktop } from '../ResponsiveRules';
import  Slider  from './Slider';

export default class PlayerHomeDesktop extends Component {
  render() {
    const { imageFiles } = this.props;
    return (
      <>
        <Desktop>
          <Slider key="home" contenKey="player" items={imageFiles} parallax={true} startsAuto={true} />
        </Desktop>
      </>
    );
  }
}
