import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import VisibilitySensor from 'react-visibility-sensor';
import { VisarPopup } from '.';

const content = {
	title: 'A intermediação imobiliária que você precisa para regularização e valorização do seu patrimônio.',
	text: [
		'Após trabalhar mais de quinze anos trabalhando na serventia de registro de imóveis de Presidente Olegário, agregando técnica e conhecimento a toda essa experiëncia de trabalho, idealizamos uma empresa que imprimisse a nossa identidade no atendimento aos clientes e parceiros de forma mais humanizada, prestando uma consultoria personalizada e contribuindo na realização dos objetivos dos nossos clientes.',
		//'Para atender as exigências do mercado e as alterações legais, estamos sempre atentos para garantir uma solução eficiente, monitorando processos a fim de eliminar obstáculos e possíveis entraves. Com o nosso conhecimento da legislação imobiliária e experiência, auxiliamos no que for necessário para registro do título, e garantimos a qualidade no serviço e menos preocupação para você oferecendo soluções seguras e eficientes.',
	]
}

const VisarBlock = () => {
	const isMobile = window.matchMedia('(max-width: 997px)').matches;
	const [isVisible, setIsVisible] = useState(false);

	return(
		<VisibilitySensor partialVisibility offset={{ bottom: 800 }} onChange={(visibility) => {(isMobile && visibility) && setIsVisible(true)}}>
			<>
				<Route path="/sobre" component={props => <VisarPopup {...props} content={content} />} />
				<div className="visar-block-wrapper">
					<div className="orange-block visar-block" name="visar">
						
						<div className="text-wrapper">
							<h2>
								Venha ter a melhor experiência <br />em atendimento e assessoria
							</h2>

							<div className="text-block-content">
								<img src={require('../img/quotes-white.svg')} />
								<p>
									Após trabalhar com documentação imobiliária por mais de quinze anos no Cartório de Registro de Imóveis, senti a necessidade de ajudar aquelas pessoas que entre idas e voltas se viam frustradas em não conseguir regularizar seus imóveis.
								</p>
								<p>
									Em virtude disso, idealizei uma empresa que oferece atendimento aos clientes e parceiros de forma mais humanizada, prestando consultoria personalizada que atendesse a necessidade de nossos clientes.
								</p>
								<p>
									<br /><br /><br />	
									<small>
										<strong>Simony Dayane</strong>
										<br />Visar Documentação Imobiliária
									</small>
								</p>
							</div>

						</div>
					</div>
				</div>
			</>
		</VisibilitySensor>
	);
}

export default VisarBlock;