import React from 'react';
import { Link } from 'react-router-dom';

// import { Container } from './styles';

function SuccessMessage({ message }) {
  return (
    <div className="success-message">
      <Link to="/">
        <img className="logo-form-simulacao"src={require('../img/logo.svg')}/>
      </Link>
      <div>
        <img src={require('../img/icon-check.svg')} alt="Icone marcação de certo"/>
        <p>{message}</p>
        <Link to="/">Voltar para o site</Link>
      </div>
    </div>
  );
}

export default SuccessMessage;