import InlineSVG from '@bit/fixcode.react.ui.inline-svg';
import classnames from "classnames";
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import ScrollLock from 'react-scrolllock';
import { Mobile } from '../ResponsiveRules';

const scrollLinkDefaultProps = {
	spy: true,
	smooth: true,
	duration: 400,
	offset: window.innerWidth <= 997 ? -94 : -110
};

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = { isMenuFixed: false, isMenuOpen: false };
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}


	handleScroll = () => {
		if (window.innerWidth >= 998) {
			const { isMenuFixed } = this.state;
			const currentScroll = document.body.scrollTop || document.body.parentNode.scrollTop;
			if (!isMenuFixed) {
				if (currentScroll >= 46)
					this.setState({ isMenuFixed: true });
			} else {
				if (currentScroll < 46)
					this.setState({ isMenuFixed: false });
			}
		}
	}


	toggleMenu = () => {
		this.setState(({ isMenuOpen }) => ({ isMenuOpen: !isMenuOpen }))
	}

	handleMenuItemClick = () => {
		if (window.innerWidth <= 997)
			this.toggleMenu();
	}

	render() {
		const { isMenuFixed, isMenuOpen } = this.state;
		const { history } = this.props;
		return (
			<div className={classnames("header-wrapper", { fixed: isMenuFixed, open: isMenuOpen })}>
				<header className="header">
					<div>
						<h1 className="logo">
							<ScrollLink to="top" {...scrollLinkDefaultProps} title="Voltar ao topo">
								<img src={require('../img/logo.svg')} alt="Visar" />
							</ScrollLink>
						</h1>
						<Mobile>
							<button className="menu-button" onClick={this.toggleMenu}>
								<img src={require('../img/menu.svg')} alt="Menu" />
							</button>
						</Mobile>
						<nav className="header-nav">
							<Mobile><button className="button-close-menu" onClick={this.toggleMenu} title="Fechar menu"><img src={require('../img/icon-close.svg')} alt="Close Menu" /></button></Mobile>
							<ul>
								<li>
									<ScrollLink to="visar" {...scrollLinkDefaultProps} onClick={this.handleMenuItemClick} title="Sobre nós">
										<span>Sobre nós</span>
									</ScrollLink>
								</li>
								<li>
									<ScrollLink to="servicos" {...scrollLinkDefaultProps} onClick={this.handleMenuItemClick} title="Serviços">
										<span>Serviços</span>
									</ScrollLink>
								</li>
								<li>
									<ScrollLink to="depoimentos" {...scrollLinkDefaultProps} onClick={this.handleMenuItemClick} title="Depoimentos">
										<span>Depoimentos</span>
									</ScrollLink>
								</li>
								<li>
									<ScrollLink to="artigos" {...scrollLinkDefaultProps} onClick={this.handleMenuItemClick} title="Artigos">
										<span>Artigos</span>
									</ScrollLink>
								</li>
								<li>
									<ScrollLink to="contato" {...scrollLinkDefaultProps} onClick={this.handleMenuItemClick} title="Contato">
										<span>Contato</span>
									</ScrollLink>
								</li>
								
							</ul>
						
							<a href="https://wa.me/5534996902987" target="_blank" rel="noopener noreferrer" title="Alguma dúvida?" className="whatsapp-header-link">
								<InlineSVG src={require('../img/icon-whatsapp.svg')} />
								Alguma dúvida?&nbsp;&nbsp;&nbsp;
								<span>34 99690-2987</span>
							</a>
						</nav>
					</div>
					
					{isMenuOpen && <ScrollLock />}
				</header>
			</div>
		)
	}
}

export default withRouter(Header)