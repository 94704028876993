import axios from '../lib/axios';

export default {
	state: { data: [], error: null },
	reducers: {
		before: state => ({ ...state, data: [], error: null }),
		onSuccess: (state, data) => ({ ...state, data }),
		onError: (state, error) => ({ ...state, error })
	},
	effects: dispatch => ({
		async fetchAll() {
			dispatch.testimonials.before();
			try {
				let { data } = await axios.get('/testimonials');
				data = data.sort(() => Math.random() - 0.5);
				dispatch.testimonials.onSuccess(data);
			} catch (error) {
				dispatch.testimonials.onError(error);
			}
		}
	})
}