import React from 'react';
import { TestimonialsList } from '.';

const TestimonialsBlock = () => (
	<div className="testimonials-block" name="depoimentos">
		<div>
			<h2>Depoimentos</h2>
			<h3>Veja o que falam sobre nossa entrega</h3>
			<TestimonialsList />
		</div>
	</div>
);

export default TestimonialsBlock;