import { ErrorMessage, Field, Form, withFormik } from 'formik';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import ReactTooltip from 'react-tooltip';
import { compose } from 'redux';
import { WeCallYouValidation } from '../validations';

const defaultMaskOptions = {
  prefix: 'R$',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '.',
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 7, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: true,
}


const Tooltip = ({ children, id}) => (
  <>
    <ReactTooltip
      id={id}
      place={window.innerWidth < 998 ? 'left' : 'top'}
      type="light"
      effect="solid"
    >
      {children}
    </ReactTooltip>
  </>
)

export const DefaultInput = ({ tooltip, name, field, form, label, className = '', ...props }) => {
  return(
    <div className={`default-input-text ${className}`}>
      <input {...props} {...field}/>
      <label>{label}</label>
      <ErrorMessage component="span" name={field.name} className="error" />
      
      {tooltip && (
        <>
          <img src={require('../img/question.svg')} alt="Interrogação" data-tip data-for="a"/>
          <Tooltip id="a">
            Lorem ipsum
          </Tooltip>
        </>
      )}
      
    </div>
  )
}

const RadioButton = props => (
  <Field name={props.name}>
    {({ field, form }) => {
      const checked = field.value === props.value;
      return (
        <>
          <label htmlFor={props.id} className="radio">{props.placeholder}
            <input
              type="radio"
              className="radio"
              {...props}
              checked={checked}
              onChange={() => form.setFieldValue(props.name, props.value)}
            />
            <span className={props.id} />
          </label>
        </>
      );
    }}
  </Field>
);

class WeCallYou extends Component {
	state = { successMessage: false }

	componentDidUpdate(prevProps) {
		const { resetForm, success } = this.props;
		if (success && prevProps.success !== success) {
			resetForm();
      prevProps.history.push('/ligamos-para-voce/sucesso')
		}
	}

	render() {
		const { error, isValid, isSaving } = this.props;
		const { successMessage } = this.state;
		return (
      <div className="ligamos-para-voce-wrapper">
        <Link to="/">
          <img className="logo-form-simulacao"src={require('../img/logo.svg')}/>
        </Link>
        <h1>Nós ligamos para você</h1>
        <div className="ligamos-para-voce">
          <Form>
            <p className="description">Informe os seus dados abaixo e entraremos em contato, sem nenhum custo para você.</p>
            <Field className="name" label="Nome" name="name" type="text" component={DefaultInput} placeholder="Nome" />
            <Field name="phone" render={({ field }) => (
              <div className="default-input-text phone">
                <MaskedInput
                  mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                  guide={false}
                  placeholder="Telefone"
                  type="text"
                  name="phone"
                  {...field}
                />
                <label>Telefone</label>
                <ErrorMessage component="span" name={field.name} className="error" />
              </div>
            )} />
            <button type="submit" disabled={!isValid || isSaving}>
              {isSaving ? 'Enviando' : 'Enviar'}
            </button>
            {error && <p className="error-message">Falha ao enviar a mensagem. Tente novamente mais tarde.</p>}
          </Form>
        </div>
      </div>
		)
	}
}

const mapStateToProps = ({ dealts: { isSaving, error, success } }) => ({ isSaving, error, success });

const mapDispatchToProps = dispatch => ({
	submit: data => dispatch.dealts.submit(data)
});

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withFormik({
		mapPropsToValues: () => ({ name: '', phone: '', service: ' ' }),
		validationSchema: WeCallYouValidation,
		handleSubmit: (values, { props: { submit, location } }) => {
      const query = new URLSearchParams(location.search).get('s');
      if(!!query){
        values.service = query;
      }
      submit(values);
    }
	})
)(WeCallYou);
