import * as Yup from 'yup'

export default () => {
	return Yup.object().shape({
		name: Yup.string()
			.required('Informe seu nome'),
		email: Yup.string()
			.required('Informe seu e-mail')
			.email('Digite um endereço de e-mail válido.'),
		phone: Yup.string()
			.required('Informe seu telefone')
			.test('phone-validation', 'Insira um telefone válido', value => value ? value.replace(/\D/g, '').length >= 10 : false),
		subject: Yup.string()
			.required('Escolha um assunto'),
		message: Yup.string()
			.required('Informe a sua mensagem')
	})
};