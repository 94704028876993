import React from 'react';
import { Element } from 'react-scroll';
import { Articles, Contato, Header, Servicos, TestimonialsBlock, TopBanner, VisarBlock } from '../components';

const Home = () => 
(
    <>
      <Element name="top" className="top"/>
      <Header />
      <main className="content-block">
        <TopBanner />
        <VisarBlock />
        <Servicos />
        <TestimonialsBlock />
        
        <Articles />
        <Contato />
      </main>
    </>
);

export default Home;