import React, { Component } from 'react'
import { connect } from 'react-redux'
import CustomPopup from '@bit/fixcode.react.ui.custom-popup';
import { ArticlesList, Loading } from '.';

export class ArticlesPopupMobile extends Component {
	componentDidMount() {
		if (!this.props.articles.length)
			this.props.fetchAll();
	}


	render() {
		const { articles, history } = this.props;
		return (
			<CustomPopup
				className={`popup-articles${articles.length === 0 ? ' isLoading' : ''}`}
				portalQuerySelector="#portal"
				onBack={() => history.push('/')}
			>
				{articles.length === 0 && <Loading />}
				{articles.length > 0 && (
					<>
						<h1>Artigos</h1>
						<h2>Nossa opinião sobre as leis e mercado.</h2>
						<ArticlesList articles={articles} noSlider />
					</>
				)}
			</CustomPopup>
		)
	}
}

const mapStateToProps = ({ articles: { all: articles } }) => ({ articles });

const mapDispatchToProps = dispatch => ({
	fetchAll: () => dispatch.articles.fetchAll()
})

export default connect(mapStateToProps, mapDispatchToProps)(ArticlesPopupMobile)
