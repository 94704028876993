import React from 'react';
import Servico from './Servico';

const servicos = [
	{
		image: require('../img/icon-assessoria.svg'),
		title: 'Assessoria',
		text: 'Profissionais experientes irão elaborar o plano de ação mais prático e seguro para você realizar seus negócios'
	},
	{
		image: require('../img/icon-regularizacao-rural.svg'),
		title: 'Regularização rural',
		text: 'Divisão, estremação, georreferenciamento, integralização de capital, fusão etc'
	},
	{
		image: require('../img/icon-regularizacao-urbana.svg'),
		title: 'Regularização urbana',
		text: 'Análise e qualificação de documentos visando simplificar seu processo'
	},

	{
		image: require('../img/icon-emissao-documentos.svg'),
		title: 'Gestão de documentos',
		text: 'Emissão, controle e planejamento de todos os documentos de seus processos'
	},
	{
		image: require('../img/icon-cartorios.svg'),
		title: 'Cartórios',
		text: 'Solicitações de certidões e acompanhamento de registros e averbações'
	},
	
	{
		image: require('../img/icon-prefeitura.svg'),
		title: 'Prefeitura',
		text: 'Requerimentos de alvarás, licenças, aprovação de processos, ITBI etc'
  },
]

const Imobiliaria = ({history}) => (
  <>
			
			<div className="servicos-list">
      	{servicos.map((servico, index) => <Servico servico={servico} key={index} />)}
   	 </div>
		
  </>
)

export default Imobiliaria;