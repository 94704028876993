import axios from '../lib/axios';

export default {
	state: { isSaving: false, success: false, data: null, error: null, hasError: false },
	reducers: {
		before: state => ({ ...state, isSaving: true, success: false, data: null, error: null, hasError: false }),
		onSuccess: (state, data) => ({ ...state, isSaving: false, data, success: true }),
		onError: (state, error) => ({ ...state, isSaving: false, error, hasError: true })
	},
	effects: (dispatch) => ({
		async submit(payload, _rootState) {
			dispatch.simulacao.before();
			try {
				const { data } = await axios.post('/simulacao', payload);
				dispatch.simulacao.onSuccess(data);
			} catch (error) {
				dispatch.simulacao.onError(error);
			}
		}
	})
}