import React from 'react'
import InlineSVG from '@bit/fixcode.react.ui.inline-svg';
import { FormContato } from '.';

const Contato = () => (
	<section className="orange-block contato-block" name="contato">
		<div className="contato-grid">
			<h2>Contato</h2>
			<h3>Entre em contato conosco</h3>
			<div className="contato-wrapper">
				<FormContato />
				<div className="contato-detalhes">
					<div className="contato-endereco">
						<img src={require('../img/icon-pin.svg')} alt="Endereço" />
						<p>Endereço</p>
						<strong>
							Praça da Independência, 51 - Loja 02 <br />Centro<br /> Presidente Olegário / MG <br />CEP: 38.750-000
						</strong>
						<a href="https://www.google.com/maps/place/Documenta%C3%A7%C3%A3o+Imobili%C3%A1ria+VISAR+CAIXA+AQUI/@-18.4157232,-46.4181016,15z/data=!4m5!3m4!1s0x0:0x811ba66556bab554!8m2!3d-18.4157232!4d-46.4181016" target="_blank" rel="noopener noreferrer" className="arrow-button" title="Como chegar">Como chegar?<img src={require('../img/icon-right-arrow.svg')} alt="" /></a>
					</div>
					<div className="contato-telefone">
						<img src={require('../img/icon-whatsapp.svg')} alt="Whatsapp" />
						<p>Telefone e Whatsapp</p>
						<strong>
							<a href="https://wa.me/5534996902987" target="_blank" rel="noopener noreferrer" title="Whatsapp">34 99690-2987</a>
						</strong>
					</div>
					<div className="social-networks">
						<p>Nossas redes sociais</p>
						<div className="social-network-items">
							<a className="social-network-link" href="https://www.instagram.com/visardoc" target="_blank" rel="noopener noreferrer" title="Instagram"><InlineSVG src={require('../img/icon-instagram.svg')} /></a>
							<a className="social-network-link" href="https://www.facebook.com/visardoc" target="_blank" rel="noopener noreferrer" title="Facebook"><InlineSVG src={require('../img/icon-facebook.svg')} /></a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
)

export default Contato;