import React from 'react'
import CustomSlider from '@bit/fixcode.react.ui.custom-slider';

const MobileSlider = ({ children, className, sliderOptions, ...props }) => (
	<>
		<CustomSlider
			{...props}
			className={`slider ${className || ''}`}
			sliderOptions={{ autoplay: false, infinite: true, ...sliderOptions }}
		>
			{children}
		</CustomSlider>
	</ >
);

export default MobileSlider
