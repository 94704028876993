import axios from '../lib/axios';

export default {
	state: { data: null, error: null },
	reducers: {
		before: state => ({ ...state, data: null, error: null }),
		onSuccess: (state, data) => ({ ...state, data }),
		onError: (state, error) => ({ ...state, error })
	},
	effects: (dispatch) => ({
		async fetch(slug) {
			dispatch.article.before();
			try {
				const { data } = await axios.get(`/articles/${slug}`, { params: { $idField: 'slug' } });
				dispatch.article.onSuccess(data);
			} catch (error) {
				dispatch.article.onError(error);
			}
		}
	})
}