import InlineSVG from '@bit/fixcode.react.ui.inline-svg';
import React from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { Mobile } from '../ResponsiveRules';
import Imobiliaria from './Imobiliaria';

const Servicos = () => {
	const {location} = useHistory();
	const match = useRouteMatch();
	const check = (match, location) => {
		if (match) {
			return true;
		} else {
			if(location.pathname !== "/correspondente-bancario") {
				return true;
			}
	
		} 
	}

	return(
		<section className="servicos-wrapper" name="servicos">
			<h2>Serviços</h2>
			<h3>Saiba como podemos te ajudar</h3>
			<Switch>
				<Route path="/" component={Imobiliaria} /> 
			</Switch>
			<Mobile>
				<a href="https://wa.me/5534996902987" target="_blank" rel="noopener noreferrer" title="Alguma dúvida?" className="whatsapp-header-link">
					<InlineSVG src={require('../img/icon-whatsapp.svg')} />
					Alguma dúvida?&nbsp;&nbsp;&nbsp;
					<span>34 99690-2987</span>
				</a>
			</Mobile>
	
		</section>
	);
}


export default Servicos;