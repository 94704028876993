import { LoadImage } from '@bit/fixcode.react.ui.custom-image';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { MobileSlider } from '.';
import { Desktop, Mobile } from '../ResponsiveRules';
import SliderTopo from './SliderTopo';


const slides = [
	
	{ 
		url: require('../img/slider/slide-desktop-regularizacao-rural.jpg'),	
		urlMobile: require('../img/slider/slide-mobile-regularizacao-rural.jpg'),
		title: 'Regularização Rural',
		text: 'Conte com nossa assessoria para resolver os entraves de forma menos burocrática e onerosa. <br />Valorize seu patrimônio, regularize e tenha maior facilidade na aprovação de crédito',
		position: 'left',
		path: 'regularizacao-rural',

	},

	{ 
		url: require('../img/slider/slide-desktop-regularizacao-urbana.jpg'),
		urlMobile: require('../img/slider/slide-mobile-regularizacao-urbana.jpg'),
		title: 'Regularização Urbana',
		text: 'Chega de burocracia e papelada!<br />Nós agilizamos todo o processo imobiliário pra você',
		position: 'left',
		path: 'regularizacao-urbana',
	},

	{ 
		url: require('../img/slider/slide-desktop-reurb.jpg'),
		urlMobile: require('../img/slider/slide-mobile-reurb.jpg'),
		title: 'Reurb',
		text: 'Não perca a <strong>OPORTUNIDADE</strong> de ter a escritura de sua casa. Conte com nossa assessoria nos processos de regularização fundiária junto a Prefeitura Municipal de sua cidade',
		position: 'left',
		path: 'reurb',
	},
]

export default class TopBanner extends Component {
	constructor(props) {
		super(props);
		const pathname = window.location.pathname.split('/')[1];;
    this.state = {
			hasAutoPlay: slides.findIndex(slide => slide.path === pathname)
		};
	}

	componentWillUnmount() {
		clearInterval(this.animation);
	}

	renderSlideContent = ({ urlMobile, title, text, button }, index) => (
		<div key={index}>
			<div className="banner-image-overlay-wrapper">
				<picture className="banner-image">
					<source srcSet={urlMobile} media="(max-width: 997px)" />
					<LoadImage src={urlMobile} alt={title} />
				</picture>
				<Desktop>
					<div className="banner-image-overlay"></div>
				</Desktop>
			</div>
			<div className="banner-text">
				<h2>{title}</h2>
				<p dangerouslySetInnerHTML={{__html: text }} />

				{button && (
					<>
						{button.target && (
							<a href={`${button?.link}`} target={button.target}>
								{button?.text}
							</a>
						)}

						{!button.target && (
							<Link to={`${button?.link}`}>{button?.text}</Link>
						)}
					</>
				)}
			</div>
		</div>
	);

	render() {
		return (
			<section className="top-banners-block">
				<Desktop>
					<SliderTopo imageFiles={slides}/>
				</Desktop>
				<Mobile>
					<MobileSlider className="top-banners-slider" sliderOptions={{ autoplay: this.state.hasAutoPlay, autoplaySpeed: 5000 }}>
						{slides.map((slide, index) => this.renderSlideContent(slide, index))}
					</MobileSlider>
				</Mobile>
			</section>
		)
	}
}
