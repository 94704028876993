import { LoadImage } from '@bit/fixcode.react.ui.custom-image';
import React from 'react';
import { Link } from 'react-router-dom';


const Servico = ({ servico: { image, title, text, link } }) => (
	<div className="servico">
		{link ? <Link to={`${link}`}>
			<div>
				<LoadImage src={image} alt={title} />
				<strong>{title}</strong>
				<p>{text}</p>
				{link && <Link to={`${link}`}>Saiba mais</Link>}
			</div>
		</Link>
		: <div>
				<LoadImage src={image} alt={title} />
				<strong>{title}</strong>
				<p>{text}</p>
				{link && <Link to={`${link}`}>Saiba mais</Link>}
			</div>
		}
	</div>
);

export default Servico;