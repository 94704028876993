import React from 'react'
import CustomPopup from '@bit/fixcode.react.ui.custom-popup';

const VisarPopup = ({ content, history }) => (
	<CustomPopup
		className="popup-visar"
		portalQuerySelector="#portal"
		onBack={() => history.push('/')}
	>
		<h1>{content.title}</h1>
		{content.text.map((p, index) => <p key={index}>{p}</p>)}
	</CustomPopup>
);

export default VisarPopup;